import axios from "axios";

export default {
  async getItemNames() {
    return await axios
      .get(`inventory/item-name`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getInventory(offset, limit) {
    return await axios
      .get(`inventory?offset=${offset}&limit=${limit}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async inventorySearch(params) {
    return await axios
      .post(`inventory/inventory-search`, params)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getMarks() {
    return await axios
      .get(`marks`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getCompanyWareHouse() {
    return await axios
      .get(`company-warehouse`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async syncWithOdoo() {
    return await axios
      .get(`Synchronization/odoo/inventory`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async syncWithERP() {
    return await axios
      .get(`Synchronization/odoo/manufacturing-orders`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
