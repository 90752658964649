<template>
  <div>
    <v-overlay :value="overlay"><v-progress-circular :size="70" :width="7" color="green"
        indeterminate></v-progress-circular></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div class="">
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">Inventory</h5>
                    <!-- <p class="text-sm text-body mb-0">
                      Possible states of a document
                    </p> -->
                    <v-spacer></v-spacer>
                  </div>
                </div>
              </div>

              <v-card-text>
                <v-card-text class="px-0 py-0">
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn @click="syncInventory" elevation="0" :ripple="false" height="43" dark
                      class="font-weight-bold text-capitalize btn-ls btn-primary bg-success py-3 px-6 mr-5">Sync
                    </v-btn>
                    <v-btn @click="syncMO()" elevation="0" :ripple="false" height="43" dark
                      class="font-weight-bold text-capitalize btn-ls btn-primary bg-success py-3 px-6 mr-5">MO Sync
                    </v-btn>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                      <label class="text-md text-typo font-weight-bolder ms-1">Item Name</label>

                      <v-autocomplete :items="item_names" item-value="item_name" item-text="item_name" v-model="item_name"
                        class="input-style font-size-input text-light-input placeholder-light select-style mt-3" outlined
                        chips multiple height="46" single-line placeholder="Select Item Name">
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0" label color="bg-default" class="py-1 px-2 my-0">
                            <span class="text-white text-caption ls-0">{{ item.item_name }}</span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text text-caption">
                            (+{{ item_name.length - 1 }}
                            others)
                          </span>
                        </template>
                        <template v-slot:prepend-item>
                          <v-list-item ripple @mousedown.prevent @click="toggle">
                            <v-list-item-action>
                              <v-icon :color="item_name.length > 0 ? 'indigo darken-4' : ''">
                                {{ icon }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title> Select All </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                      <label class="text-md text-typo font-weight-bolder ms-1">Mark</label>

                      <v-autocomplete :items="marks" item-value="id" item-text="mark_name" v-model="mark"
                        class="input-style font-size-input text-light-input placeholder-light select-style mt-3" outlined
                        chips multiple height="46" single-line placeholder="Select Mark">
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0" label color="bg-default" class="py-1 px-2 my-0">
                            <span class="text-white text-caption ls-0">{{ item.mark_name }}</span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text text-caption">
                            (+{{ mark.length - 1 }}
                            others)
                          </span>
                        </template>
                        <template v-slot:prepend-item>
                          <v-list-item ripple @mousedown.prevent @click="toggle1">
                            <v-list-item-action>
                              <v-icon :color="mark.length > 0 ? 'indigo darken-4' : ''">
                                {{ icon1 }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title> Select All </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                      <label class="text-md text-typo font-weight-bolder ms-1">Company WareHouse</label>
                      <v-autocomplete :items="company_warehouses" item-value="id" item-text="name"
                        v-model="company_warehouse"
                        class="input-style font-size-input text-light-input placeholder-light select-style mt-3" outlined
                        chips multiple height="46" single-line placeholder="Select Company WareHouse">
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0" label color="bg-default" class="py-1 px-2 my-0">
                            <span class="text-white text-caption ls-0">{{ item.name }}</span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text text-caption">
                            (+{{ company_warehouse.length - 1 }}
                            others)
                          </span>
                        </template>
                        <template v-slot:prepend-item>
                          <v-list-item ripple @mousedown.prevent @click="toggle2">
                            <v-list-item-action>
                              <v-icon :color="company_warehouse.length > 0 ? 'indigo darken-4' : ''">
                                {{ icon2 }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title> Select All </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="card-padding d-flex justify-end">
                  <v-checkbox v-model="checkbox" label="Include items with zero stock"></v-checkbox>
                  <v-spacer></v-spacer>
                  <v-btn @click="cancel" elevation="0" :ripple="false" height="46"
                    class="font-weight-bold text-capitalize btn-ls btn-secondary bg-light py-3 px-6">Cancel</v-btn>

                  <v-btn @click="search" elevation="0" :ripple="false" height="46" dark
                    class="font-weight-bold text-capitalize btn-ls btn-primary bg-success py-3 px-6">Search</v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-card class="card-shadow border-radius-xl mt-5">
        <v-card-text class="card-padding">
          <v-data-table :headers="headers" :pageCount="numberOfPages" :server-items-length="total_rows"
            :options.sync="options" :items="inventoryData" fixed-header class="table">
            <template v-slot:[`item.po_date`]="{ item }">{{ formatdate(item.po_date) }} </template>
            <template v-slot:[`item.grn_date`]="{ item }">{{ formatdate(item.grn_date) }} </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Purchase history</v-toolbar-title>

                <v-spacer></v-spacer>
                <v-btn @click="excelExport" elevation="0" :ripple="false" height="43" dark
                  class="font-weight-bold text-capitalize btn-primary bg-success py-5 px-5 shadow">
                  <v-icon size="20" class="text-white mr-1"> fas fa-cloud-download-alt </v-icon>
                  Download
                </v-btn>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import formatDate from "../../../../global-data/dateFormatter";
import { json2excel } from "js2excel";
import api from "../api";
export default {
  data() {
    return {
      overlay: false,
      item_names: [],
      item_name: [],

      marks: [],
      mark: [],
      inventoryData: [],
      company_warehouses: [],
      company_warehouse: [],
      headers: [
        {
          text: "Company Warehouse",
          value: "company_warehouse",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "Week",
          value: "week",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "PO No.",
          value: "po_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "PO Date",
          value: "po_date",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "GRN Date",
          value: "grn_date",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "GRN No.",
          value: "grn_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "GRN Location",
          value: "grn_location",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "Vendor",
          value: "vendor",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "Vendor Type",
          value: "vendor_type",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "Category",
          value: "category",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "Item Name",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "Batch No.",
          value: "batch_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "Mark",
          value: "mark",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "Grade",
          value: "grade",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "Invoice",
          value: "invoice",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "Bay",
          value: "bay",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
        },
        {
          text: "Bags",
          value: "no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "right",
          sortable: false,
        },
        {
          text: "Wt (Kg)",
          value: "net_kgs",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "right",
          sortable: false,
        },
        {
          text: "Nett (Kg)",
          value: "total_net_kgs",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "right",
          sortable: false,
        },
        {
          text: "Rate",
          value: "purchased_rate",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "right",
          sortable: false,
        },
        {
          text: "Amount",
          value: "stock_value",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "right",
          sortable: false,
        },
      ],
      options: {
        itemsPerPage: 10,
      },
      numberOfPages: 4,
      total_rows: 0,
      checkbox: false,
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    formatdate(date) {
      return formatDate.formatDate(date);
    },
    toggle1() {
      this.$nextTick(() => {
        if (this.selectAllMark) {
          this.mark = [];
        } else {
          this.mark = this.marks.slice();
        }
      });
    },
    toggle2() {
      this.$nextTick(() => {
        if (this.selectAllCompanyWareHouse) {
          this.company_warehouse = [];
        } else {
          this.company_warehouse = this.company_warehouses.slice();
        }
      });
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAll) {
          this.item_name = [];
        } else {
          this.item_name = this.item_names.slice();
        }
      });
    },
    async initialize() {
      this.overlay = true;
      this.item_names = await api.getItemNames();
      const { page, itemsPerPage } = this.options;
      let result = await api.getInventory(page - 1, itemsPerPage);

      console.log("result", result);
      this.total_rows = result.total_rows;
      this.inventoryData = result.data;

      this.company_warehouses = await api.getCompanyWareHouse();
      this.marks = await api.getMarks();
      this.overlay = false;
    },
    cancel() {
      this.item_names = [];
      this.marks = [];
      this.company_warehouses = [];
    },
    async search() {
      let item_names = [];
      let mark = [];
      let company_warehouse = [];
      // Checking Item name -SELECT ALL-
      if (this.item_name.length == this.item_names.length) {
        this.item_name.forEach((el) => {
          item_names.push(el.item_name);
        });
      }
      // SELECT SOME
      if (this.item_name.length > 0 && this.item_name.length != this.item_names.length) {
        item_names = this.item_name;
      }
      // Checking Marks -SELECT ALL-
      if (this.mark.length == this.marks.length) {
        this.mark.forEach((el) => {
          mark.push(el.id);
        });
      }
      // SELECT SOME
      if (this.mark.length > 0 && this.mark.length != this.marks.length) {
        mark = this.mark;
      }
      // Checking Warehouse -SELECT ALL-
      if (this.company_warehouse.length == this.company_warehouses.length) {
        this.company_warehouse.forEach((el) => {
          company_warehouse.push(el.id);
        });
      }
      // SELECT SOME
      if (this.company_warehouse.length > 0 && this.company_warehouse.length != this.company_warehouses.length) {
        company_warehouse = this.company_warehouse;
      }
      const { page, itemsPerPage } = this.options;
      let params = {
        item_name: item_names,
        mark: mark,
        company_warehouse: company_warehouse,
        offset: page - 1,
        limit: itemsPerPage === -1 ? this.total_rows : itemsPerPage,
        zeroStock: this.checkbox,
      };
      this.inventoryData = [];
      try {
        this.overlay = true;

        let result = await api.inventorySearch(params);
        this.total_rows = parseInt(result.total_rows);
        this.inventoryData = result.data;

        this.overlay = false;
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    // Sync OInventory With Odoo
    async syncInventory() {
      this.overlay = true;
      await api.syncWithOdoo().then(() => {
        this.search();
      });
      this.overlay = false;
    },
    async syncMO() {
      this.overlay = true;
      await api.syncWithERP().then(() => {
        this.search();
      });
      this.overlay = false;
    },

    async excelExport() {
      this.options = {
        page: 1,
        itemsPerPage: -1,
      };
      await this.search();
      let data = this.inventoryData.map((item, i) => {
        return {
          "#": i + 1,
          "Company Warehouse": item.company_warehouse,
          "Week": item.week,
          "PO No.": item.po_no,
          "PO Date": this.formatdate(item.po_date),
          "GRN Date": this.formatdate(item.grn_date),
          "GRN No.": item.grn_no,
          "GRN Location": item.grn_location,
          "Vendor": item.vendor,
          "Vendor Type": item.vendor_type,
          "Category": item.category,
          "Item Name": item.item_name,
          "Batch No.": item.batch_no,
          "Mark": item.mark,
          "Grade": item.grade,
          "Invoice": item.invoice,
          "Bay": item.bay,
          "Bags": item.no_of_packages,
          "Wt": item.net_kgs,
          "Nett": item.total_net_kgs,
          "Rate": item.purchased_rate,
          "Amount": item.stock_value,
        };
      });

      try {
        let today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10);

        json2excel({
          data,
          name: "Inventory - " + today,
          formateDate: "yyyy/mm/dd",
        });
      } catch (e) {
        console.error("Excel export error:", e);
      }
    },

    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },
    showNoDataAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,

        timer: 3000,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
  },
  computed: {
    selectAll() {
      return this.item_name.length === this.item_names.length;
    },
    selectSome() {
      return this.item_name.length > 0 && !this.selectAll;
    },
    icon() {
      if (this.selectAll) return "ni-fat-remove text-lg";
      if (this.selectSome) return "ni-fat-delete";
      return "fa-square-o";
    },
    selectAllMark() {
      return this.mark.length === this.marks.length;
    },
    selectSomeMark() {
      return this.mark.length > 0 && !this.selectAllMark;
    },

    icon1() {
      if (this.selectAllMark) return "ni-fat-remove text-lg";
      if (this.selectSomeMark) return "ni-fat-delete";
      return "fa-square-o";
    },

    selectAllCompanyWareHouse() {
      return this.company_warehouse.length === this.company_warehouses.length;
    },
    selectSomeCompanyWareHouse() {
      return this.company_warehouse.length > 0 && !this.selectAllCompanyWareHouse;
    },

    icon2() {
      if (this.selectAllCompanyWareHouse) return "ni-fat-remove text-lg";
      if (this.selectSomeCompanyWareHouse) return "ni-fat-delete";
      return "fa-square-o";
    },
  },
  watch: {
    options: {
      handler() {
        this.search();
      },
    },
    deep: true,
  },
};
</script>

<style></style>
